import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';

// Project
// import Logo from '/assets/images/TcitLogo_123x50.png';
import navbarStyles from './navbar.styles';
import MyDrawer from './Drawer';

const TCITNavbarLogo = `${window.location.origin}/images/salfa-logo.png`;

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  return (
    <AppBar position="static" color="inherit">
      <MyDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
      <Toolbar>
        <IconButton onClick={toggleDrawer} edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <IconButton onClick={() => navigate('/')}>
          <img style={{ height: '40px' }} src={TCITNavbarLogo} alt="" />
        </IconButton>

        <Typography variant="h6" sx={navbarStyles.title} />
      </Toolbar>
    </AppBar>
  );
}

NavBar.propTypes = {
  user: PropTypes.shape({}),
};

NavBar.defaultProps = {
  user: null,
};

export default NavBar;
