import { Card, CardContent, Grid, Typography } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import React from 'react';

// const paymentType = {
//   VD: 'Venta Débito',
//   VN: 'Venta Normal',
//   VC: 'Venta en cuotas',
//   SI: '3 cuotas sin interés',
//   S2: '2 cuotas sin interés',
//   NC: 'N Cuotas sin interés',
//   VP: 'Venta Prepago',
// };

function TransactionDetail() {
  // if (transactionData?.details[0]?.response_code === 0)
  return (
    <Grid justifyContent="center" container>
      <Card
        sx={{
          marginTop: 4,
          minWidth: '60%',
          boxShadow: '0 5px 25px 0 rgb(175 175 175 / 30%)',
        }}
        variant="outlined">
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 125 }} color="success" />
          <Typography fontSize={24} fontWeight="bold" sx={{ marginBottom: 5 }}>
            Transacción completada
          </Typography>
          {/* <Typography>Número de orden: {transactionData.buy_order}</Typography>
            <Typography>Monto: {transactionData.details[0].amount}</Typography>
            <Typography>
              Código de autorización: {transactionData.details[0].authorization_code}
            </Typography>
            <Typography>Fecha de la transacción: {transactionData.buy_order}</Typography>
            <Typography>
              Tipo de pago realizado: {paymentType[transactionData.details[0].payment_type_code]}
            </Typography>
            <Typography>
              Cantidad de cuotas: {transactionData.details[0].installments_number}
            </Typography>
            <Typography>
              Monto de cada cuota: {transactionData.details[0]?.installments_amount || '-'}
            </Typography>
            <Typography>
              Últimos 4 digitos de la tarjeta: {transactionData.card_detail.card_number}
            </Typography> */}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default TransactionDetail;
