import { Grid, Skeleton } from '@mui/material';
import React from 'react';

function SkeletonResult() {
  return (
    <Grid marginTop={4} justifyContent="center" container>
      <Skeleton variant="rectangular" width="60%" height={450} />
    </Grid>
  );
}

export default SkeletonResult;
