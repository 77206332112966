import { Card, CardContent, Grid, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import React from 'react';

function TransactionDetail() {
  return (
    <Grid justifyContent="center" container>
      <Card
        sx={{
          marginTop: 4,
          minWidth: '60%',
          boxShadow: '0 5px 25px 0 rgb(175 175 175 / 30%)',
        }}
        variant="outlined">
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CancelIcon sx={{ fontSize: 125, color: '#e10600' }} />
          <Typography fontSize={24} fontWeight="bold" sx={{ marginBottom: 5 }}>
            Transacción fallida
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default TransactionDetail;
