import React from 'react';

import { Grid, Typography } from '@mui/material';

function Home() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Portal de Pago</Typography>
      </Grid>
    </Grid>
  );
}

export default Home;
