/* Redux Saga */
import { all } from 'redux-saga/effects';

/* Project */
import AppSaga from 'content/app.saga';
import { CounterSaga } from 'content/features/counter';
import { UserTasksSaga } from 'content/features/tasks';
import { RecoverySaga, LoginSaga, RegisterSaga } from 'content/features/authentication';

export default function* MainSaga() {
  yield all([
    LoginSaga(),
    CounterSaga(),
    AppSaga(),
    UserTasksSaga(),
    RecoverySaga(),
    RegisterSaga(),
  ]);
}
