import React from 'react';

/* Material UI */
import { Grid } from '@mui/material';
import { Footer, NavBar } from '../../shared';

function Layout(props) {
  const { children, element } = props;

  return (
    <>
      <NavBar />
      <div className="content-container">
        <Grid container justifyContent="center" sx={{ paddingTop: '16px' }}>
          <Grid container item md={11} sm={12}>
            {element || children}
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
