import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

function MyDrawer({ isOpen, toggleDrawer }) {
  const drawerWidth = 250; // Set your desired width here

  return (
    <Drawer anchor="left" open={isOpen} onClose={toggleDrawer}>
      <List sx={{ minWidth: drawerWidth }}>
        <ListItem button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Item 2" />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default MyDrawer;
