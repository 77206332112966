/* Material UI */
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

/* Router */
import Home from 'content/pages/Home/Home';
import Login from 'content/pages/Login/Login';
import Payment from 'content/pages/AccountStatus/Payment';
import PaymentResult from 'content/pages/AccountStatus/PaymentResult';
import PaymentError from 'content/pages/AccountStatus/PaymentError';

const Paths = {
  publics: [
    {
      path: '/',
      name: 'Inicio',
      component: Home,
      useLayout: true,
      Icon: HomeIcon,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      useLayout: false,
      Icon: AccountCircleIcon,
      isAuth: true,
    },
    {
      path: '/account-status/:clientId/payment',
      name: 'Account Status',
      component: Payment,
      useLayout: true,
    },
    {
      path: '/payment-result',
      name: 'Payment Result',
      component: PaymentResult,
      useLayout: true,
    },
    {
      path: '/servipag/payment-notification',
      name: 'Servipag Result One',
      component: Payment,
      useLayout: true,
    },
    {
      path: '/servipag/payment-confirm',
      name: 'Servipag Result Two',
      component: PaymentResult,
      useLayout: true,
    },
    {
      path: '/servipag/payment-error',
      name: 'Servipag Result Two',
      component: PaymentError,
      useLayout: true,
    },
  ],
  shared: [],
  protecteds: [],
  independents: [],
};

export default Paths;
