import React, { useEffect, useState } from 'react';
import SkeletonResult from './SkeletonResult';
import TransactionDetail from './TransactionDetail';

function PaymentResult() {
  const [isLoading, setIsLoading] = useState(false);
  const [transactionData, setTransactionData] = useState({});

  useEffect(() => {
    const mockTransaction = {};
    setTransactionData(mockTransaction);
    setIsLoading(false);
  }, []);

  if (isLoading) return <SkeletonResult />;

  return <TransactionDetail transactionData={transactionData} />;
}

export default PaymentResult;
