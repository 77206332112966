import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  Radio,
  Typography,
  // FormLabel,
  RadioGroup,
  FormControlLabel,
  Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import servipag from './servipag.png';
import PaymentSkeleton from './PaymentSkeleton';

// eslint-disable-next-line complexity
function Payment() {
  const { clientId } = useParams();
  const [loading, setLoading] = useState(false);
  const [debt, setDebt] = useState(null);
  const [clientName, setClientName] = useState('');

  // const [paymentPayload, setPaymentPayload] = useState();
  const [debtToPay, setDebtToPay] = useState({
    debt: '',
    total: '',
  });

  const formatNumber = (num) => num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  const navigate = useNavigate();
  useEffect(() => {
    // eslint-disable-next-line complexity
    const fetchClientDebt = async () => {
      if (!clientId) {
        setLoading(false);
        navigate('/*');
        return;
      }
      try {
        const response = await fetch(`${window.location.origin}/api/v1/account-status/${clientId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        const { total, expired, name } = await response.json();
        setDebt({
          total: total > 0 ? formatNumber(total) : 0,
          expired: expired > 0 ? formatNumber(expired) : 0,
          toExpire: total - expired,
          rawExpired: expired,
        });

        setClientName(name);
      } catch (e) {
        navigate('/*');
      }
      setLoading(false);
    };
    setLoading(true);
    fetchClientDebt();
  }, []);

  const url = `${window.location.origin}/api/v1/confirm/${clientId}`;

  const handlePayment = async () => {
    const response = await axios.post(url, { debtToPay });

    try {
      const form = document.createElement('form');
      const xml = response.data.xml1Response.xml1;
      form.method = 'post';
      form.action = 'https://www.servipag.com/BotonPago/BotonPago/Pagar';

      // Crear un campo de texto oculto para el XML
      const xmlInput = document.createElement('textarea');
      xmlInput.name = 'xml';
      xmlInput.value = xml;
      xmlInput.style.display = 'none';

      // Agregar el campo de texto al formulario
      form.appendChild(xmlInput);

      // Agregar el formulario al cuerpo del documento
      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handlePaymentObj = (event) => {
    let total = 0;
    if (event.target.value === 'total') total = debt.total;
    if (event.target.value === 'expired') total = debt.expired;

    setDebtToPay({
      debt: event.target.value,
      total,
    });
  };

  if (loading || !debt) {
    return <PaymentSkeleton />;
  }

  return (
    <Grid container sx={{ m: { xs: 2 } }}>
      <Grid item sx={{ margin: '24px 0' }}>
        <Typography
          sx={{
            fontWeight: 700,
            color: '#012D55',
          }}
          variant="h4"
          fontSize={36}>
          Botón de pago
        </Typography>
      </Grid>
      <Grid item container spacing={7}>
        <Grid flexDirection="column" container item xs={12} md={6} lg={8}>
          <Grid item container>
            <Grid item sx={{ margin: '0px 0px 24px 0px' }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: '#012D55',
                }}
                variant="h5"
                fontSize={24}>
                Seleccione monto a pagar:
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" fullWidth>
              <FormControl fullWidth>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  onChange={handlePaymentObj}
                  value={debtToPay.debt}
                  name="radio-buttons-group">
                  <Grid item>
                    <Card
                      sx={{
                        marginBottom: '24px',
                        boxShadow: '0 5px 25px 0 rgb(175 175 175 / 30%)',
                        padding: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      variant="outlined">
                      <Box display="flex" alignItems="center" sx={{ height: '36px' }}>
                        <FormControlLabel
                          value="total"
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': {
                                  color: '#E10600',
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                fontWeight: 600,
                              }}
                              fontSize={16}>
                              Deuda total
                            </Typography>
                          }
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          color: '#012D55',
                        }}
                        fontSize={16}>
                        ${debt.total}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      sx={{
                        marginBottom: '32px',
                        boxShadow: '0 5px 25px 0 rgb(175 175 175 / 30%)',
                        padding: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      variant="outlined">
                      <Box display="flex" alignItems="center" sx={{ height: '36px' }}>
                        <FormControlLabel
                          value="expired"
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': {
                                  color: '#E10600',
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                fontWeight: 600,
                              }}
                              fontSize={16}>
                              Deuda vencida
                            </Typography>
                          }
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          color: '#012D55',
                        }}
                        fontSize={16}>
                        ${debt.expired}
                      </Typography>
                    </Card>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontWeight: 700,
                color: '#012D55',
              }}
              variant="h5"
              fontSize={24}>
              Método de pago
            </Typography>
            <Card
              sx={{
                margin: '24px 0px',
                boxShadow: '0 5px 25px 0 rgb(175 175 175 / 30%)',
                // padding: 2,
                paddingLeft: 2,
                paddingRight: 2,
                display: 'flex',
                justifyContent: 'space-between',
              }}
              variant="outlined">
              <Radio
                checked
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                sx={{
                  color: '#E10600',
                  '&.Mui-checked': {
                    color: '#E10600',
                  },
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                  height: '68px',
                }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                  fontSize={16}>
                  Servipag
                </Typography>
                <img height="54px" src={servipag} alt="Servipag" />
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card
            sx={{
              boxShadow: '0 5px 25px 0 rgb(175 175 175 / 30%)',
              padding: '32px 42px',
              borderRadius: '16px',
            }}
            variant="outlined">
            <Box sx={{ height: '335px' }}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{ height: '100%' }}>
                <Grid item container spacing={4}>
                  <Grid item>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#012D55',
                      }}
                      variant="h5"
                      fontSize={24}>
                      Detalle de pago
                    </Typography>
                  </Grid>
                  <Grid item sx={{ width: '100%' }}>
                    <Typography fontSize={16} fontWeight="600" lineHeight={2}>
                      Empresa:
                      <span style={{ float: 'right', color: '#012D55', fontWeight: '700' }}>
                        {clientName}
                      </span>
                    </Typography>
                    <Typography fontSize={16} fontWeight="600" lineHeight={2}>
                      Deuda por vencer:
                      <span style={{ float: 'right', fontWeight: '400' }}>
                        ${formatNumber(debt.toExpire)}
                      </span>
                    </Typography>
                    <Typography fontSize={16} fontWeight="600" lineHeight={2}>
                      Deuda vencida:
                      <span style={{ float: 'right', fontWeight: '400' }}>
                        ${formatNumber(debt.rawExpired)}
                      </span>
                    </Typography>
                    <Typography fontSize={16} lineHeight={2} fontWeight="600">
                      Intereses:
                      <span style={{ float: 'right', fontWeight: '400' }}>$0</span>
                    </Typography>
                    <Divider sx={{ margin: '23px 0px 17px 0px' }} />
                    <Typography fontSize={16} fontWeight="700">
                      Total a pagar:{' '}
                      <span style={{ float: 'right', color: '#012D55' }}>${debt.total}</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    disabled={debtToPay.debt === ''}
                    sx={{
                      width: '100%',
                      backgroundColor: '#E10600',
                      '&:hover': {
                        backgroundColor: '#bf0500',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#E10600',
                        color: 'white',
                        opacity: 0.5,
                      },
                      textTransform: 'none',
                    }}
                    color="primary"
                    variant="contained"
                    onClick={handlePayment}>
                    <Typography fontSize={15} fontWeight="700" color="white">
                      Pagar
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Payment;
