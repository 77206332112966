import { indigo, teal } from '@mui/material/colors';

/**
 * @type {import('@mui/material').ThemeOptions}
 */
const defaultTheme = {
  palette: {
    primary: {
      main: '#002855',
      contrastText: '#fff',
    },
    secondary: {
      main: teal[900],
    },
    title: {
      main: indigo[900],
      contrastText: '#fff',
    },
    text: {
      title: 'rgb(112, 76, 182)',
      number: 'rgb(81,82,82)',
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
};

export default defaultTheme;
