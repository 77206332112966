import { Grid, Skeleton } from '@mui/material';
import React from 'react';

function PaymentSkeleton() {
  return (
    <Grid marginTop={4} justifyContent="space-between" spacing={5} container>
      <Skeleton variant="rectangular" width="65%" height={600} />
      <Skeleton variant="rectangular" width="30%" height={450} />
    </Grid>
  );
}

export default PaymentSkeleton;
