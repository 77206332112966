import { getItemInStorage, truthty } from './functions';

export const apiSuccess = (entity, payload) => ({
  type: entity,
  payload,
});

const csrfTokenFromCookie = () => {
  const csrfToken = window.document.cookie
    .split('; ')
    .find((cookie) => cookie.trim().startsWith('XSRF-TOKEN'));

  return csrfToken?.split('=')[1];
};

const clearStateFromStorage = (clear) => {
  if (clear) {
    localStorage.clear();
    window.location.replace(window.location.origin);
  }
};

const handleResponse = ({ response, isLogin }) => {
  const payload = {
    ok: response.ok,
    clearState: response.status === 401 && isLogin === false,
    error: !response.ok,
    message: response.message,
  };

  clearStateFromStorage(payload.clearState);

  return payload;
};

const handleBadResponseReturn = ({ response, clearState, error }) => {
  if (clearState) return null;

  return response.json().then((result) => ({ error, ...result }));
};

const fetchToUrl = ({ url, content, isLogin }) =>
  fetch(`${window.location.origin}/${url}`, content)
    .then((response) => {
      const { ok, clearState, error } = handleResponse({ response, isLogin });

      if (ok) return response.json();

      return handleBadResponseReturn({ response, clearState, error });
    })
    .catch((response) => {
      // eslint-disable-next-line no-console
      console.error(response);
    });

const appendAutorizationHeader = ({ headers, session }) => {
  const newHeaders = { ...headers };

  if (session?.token) {
    newHeaders.Authorization = `Bearer ${session.token}`;
  }

  return newHeaders;
};

function* apiRequest(url, options = {}, useDefaultHeader = true, isLogin = null) {
  const session = getItemInStorage('user');
  // const csrfToken = getValueInStorage('csrfToken');
  const csrfToken = csrfTokenFromCookie();

  const defaultHeaders = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken,
  };

  const content = options;
  if (useDefaultHeader) {
    content.headers = { ...content.headers, ...defaultHeaders };
  }

  content.headers = appendAutorizationHeader({ headers: content.headers, session });

  return yield fetchToUrl({ url, content, isLogin });
}

const encodeArray = ({ obj, key }) =>
  Array.isArray(obj[key])
    ? obj[key].map((o) => `${encodeURIComponent(key)}[]=${encodeURIComponent(o)}`).join('&')
    : `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;

export function objectToQueryString(obj) {
  return Object.keys(obj)
    .filter((key) => truthty(obj[key]))
    .map((key) => {
      if (truthty(obj[key])) {
        return encodeArray({ obj, key });
      }

      return '';
    })
    .join('&');
}

export function* post(path, body) {
  const options = {
    method: 'post',
    body: JSON.stringify(body),
  };

  return yield apiRequest(path, options);
}

export function* postForm(path, body) {
  const session = getItemInStorage('user');
  const options = {
    method: 'post',
    body,
    headers: {
      Authorization: `Bearer ${session && session.token}`,
    },
  };

  return yield apiRequest(path, options, false);
}

export function* update(path, body) {
  const options = {
    method: 'put',
    body: JSON.stringify(body),
  };

  return yield apiRequest(path, options);
}

export function* destroy(path, query) {
  const options = {
    method: 'delete',
  };
  return yield apiRequest(`${path}${query ? `?${query}` : ''}`, options);
}

export function* get(path, query) {
  const options = {
    method: 'get',
  };
  return yield apiRequest(`${path}${query ? `?${query}` : ''}`, options);
}

export default apiRequest;
