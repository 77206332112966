import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Button, Grid, Typography, Card } from '@mui/material';

function SentryFallback(props) {
  const { error, componentStack, resetError } = props;
  // eslint-disable-next-line no-console
  console.log({ componentStack });

  const handleClick = () => {
    resetError();
    // eslint-disable-next-line no-console
    console.clear();
  };

  if (process.env.NODE_ENV === 'production') {
    return (
      <Grid container sx={{ margin: '2rem', width: '90vw' }} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Lo sentimos, ha ocurrido un error inesperado. Por favor, inténtelo de nuevo más tarde
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container sx={{ margin: '2rem', width: '90vw' }} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">You have encountered an error</Typography>
      </Grid>

      <Grid item xs={12}>
        <Card sx={{ padding: '1rem' }}>
          <code>{error.toString()}</code>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="info" onClick={handleClick}>
          Click here to return to the app
        </Button>
      </Grid>
    </Grid>
  );
}

SentryFallback.propTypes = {
  error: PropTypes.shape({}).isRequired,
  componentStack: PropTypes.string.isRequired,
  resetError: PropTypes.func.isRequired,
};

export default SentryFallback;
