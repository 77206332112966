import React, { useEffect } from 'react';

/* Material UI */
import { createTheme, ThemeProvider } from '@mui/material';

/* React Redux  */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Toastify */
import { ToastContainer } from 'react-toastify';

/* Project */
import './App.scss';
import defaultTheme from 'utils/theme';
import environments from 'config/environments';
import Router from './handlers/Router';
import { getStateFromApi } from './app.actions';

function App({ route }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect route for test environment
    if (route) navigate(route);
  }, [route]);

  const user = useSelector((state) => state.app.user);
  const loading = useSelector((state) => state.app.loading);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: environments.GOOGLE_CLIENT_ID,
      });
    }

    dispatch(getStateFromApi());
  }, []);

  const theme = createTheme(defaultTheme);

  return (
    <ThemeProvider theme={theme}>
      <Router user={user} loading={loading} />
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
